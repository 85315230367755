<template>
  <div class="otc-page">
    <div class="otc-title">
      <div class="left">{{ $t('otc.title') }}</div>
      <div class="right" @click="$gbUtils.handleToPage('/myHangOrder')">
        <span>{{ $t('otc.myOrder') }}</span>
        <van-icon name="arrow" />
      </div>
    </div>
    <div class="last-price">
      <div class="price-box">
        <span>{{ lastPrice }}</span>
        <span>MUSD/MAI</span>
      </div>
      <div class="ptice-tips">{{ $t('otc.lastPrice') }}</div>
    </div>
    <!-- 卖单 -->
    <OrderBox :type="2" :lastPrice="lastPrice || 0"/>
    <!-- 买单 -->
    <OrderBox :type="1" :lastPrice="lastPrice || 0" />
  </div>
</template>

<script>
import contractOtc from '../../contracts/contractOtc'
import OrderBox from './components/OrderBox.vue'

export default {
  name: '',
  components: { OrderBox },
  data () {
    return {
      lastPrice: 0,
      type: 2 // 1买; 2-卖
    }
  },
  methods: {
    async getLastPrice () {
      const resp = await contractOtc.lastPrice()
      if (!resp.success) return
      this.lastPrice = resp.result
    },
    init () {
      this.getLastPrice()
    }
  },
  mounted () {
    this.init()
  }
}
</script>

<style scoped lang="scss">
.otc-page {
  color: #fff;
  padding: 24px;
  box-sizing: border-box;

  .otc-title {
    display: flex;
    align-items: center;
    justify-content: space-between;

    .left {
      font-size: 16px;
      font-weight: 500;
    }

    .right {
      color: rgba(255, 255, 255, 0.8);
      font-size: 14px;
    }
  }

  .last-price {
    margin-top: 30px;
    text-align: center;

    .price-box {
      line-height: 1.5em;
      font-size: 18px;
      background: linear-gradient(90deg, #FFAD43 22.52%, #FF7C6B 38.33%, #FD5090 53.24%, #662BFC 65.89%);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;

      :first-child {
        font-size: 24px;
        margin-right: 10px;
        font-weight: 500;
      }
    }
    .ptice-tips {
      font-size: 16px;
      color: rgba(255, 255, 255, 0.6);
      line-height: 1.5em;
    }
  }
}</style>
