<template>
  <VanPopup v-model="show"
   style="background: transparent; width:80%; max-width: 375px; min-width: 300px;"
   class="otc-pop"
   @click-overlay="handleClose">
   <div class="pop-body">
     <div class="pop-title">{{ $t('otc.hangSale') }}</div>
     <van-field
     class="pop-input"
     input-align="right"
     type="number"
     v-model="price"
     :formatter="formatterNum"
     :label="$t('otc.price')"
     :placeholder="$t('otc.nowPrice', {price: lastPrice})" />
     <van-field
     class="pop-input"
     input-align="right"
     type="number"
     v-model="amount"
     :label="$t('otc.hangAmount')"
     :placeholder="$t('otc.pleaseInput')">
     <template #button>
       <van-button class="max-btn" size="small" color="#5E12E7"
       :disabled="!price"
       @click="handleMax"
       >Max</van-button>
     </template>
   </van-field>
     <div class="pop-balance">MAI: {{ $gbUtils.formatBalance(balance) }}</div>
     <div class="pop-tips">{{ $t('otc.hangBuyTip', {num: minAmount}) }}</div>
     <div class="pop-tips"> {{ $t('otc.expectedToObtain', {num: needAll}) }}</div>
     <van-button class="pop-btn"
     v-if="auth"
     :loading="hangLoading"
     :disabled="price <= 0 ||  amount < +minAmount || amount > +balance"
     @click="handlePending"
     >{{ $t('otc.submitHang') }}</van-button>
     <van-button class="pop-btn"
     v-else
     :loading="approveLoading"
     @click="approve"
     >{{ $t('otc.approve') }}</van-button>
   </div>
   <div class="close-icon" @click="handleClose">
     <img src="@/assets/close.png"/>
   </div>
</VanPopup>
</template>

<script>
import contractOtc from '../../../contracts/contractOtc'
import contractToken from '../../../contracts/contractToken'
import config from '@/config/app.config.js' // 引入配置文件

export default {
  name: '',
  props: ['lastPrice'],
  data () {
    return {
      show: true,
      type: 2, // 1 买 2 卖
      price: '',
      amount: '',
      tokenAddress: '',
      hangLoading: false,
      balance: 0,
      minAmount: 0,
      auth: false,
      approveLoading: true
    }
  },
  computed: {
    needAll () {
      return (this.price * this.amount) || 0
    }
  },
  methods: {
    formatterNum (value) {
      return value.replace(/[^0-9.]/g, '').replace(/^(-)*(\d+)\.(\d\d).*$/, '$1$2.$3')
    },
    handleClose () {
      this.$emit('close')
    },
    async init () {
      this.getMinAmount()
      await this.gettokenAddress()
      this.getBalance()
      this.getAllowance()
    },
    async getMinAmount () {
      const resp = await contractOtc.MIN_AMOUNT()
      if (!resp.success) return
      this.minAmount = resp.result || 0
    },
    async gettokenAddress () {
      const resp = await contractOtc.MAI()
      if (!resp.success) return
      this.tokenAddress = resp.result
    },
    async getBalance () {
      if (!this.tokenAddress) return
      const resp = await this.$web3.balanceOf(this.tokenAddress)
      if (!resp.success) return
      this.balance = resp.result || 0
    },
    handleMax () {
      this.amount = this.balance
    },
    // 获取授权额度
    async getAllowance () {
      // 获取授权额度
      this.approveLoading = true
      const resp = await contractToken.allowance(
        this.tokenAddress,
        config.otc // 合约地址
      )
      this.approveLoading = false
      if (!resp.success) return
      this.auth = resp.result > 0
    },
    // 授权
    async approve () {
      this.approveLoading = true
      const resp = await contractToken.approve(
        this.tokenAddress,
        config.otc // 合约地址
      )
      this.approveLoading = false
      if (!resp.success) return this.$toast('fail')
      this.$toast('Success')
      this.auth = true
    },
    // 掛單
    async handlePending () {
      this.hangLoading = true
      const resp = await contractOtc.pending(this.type, this.price, this.amount)
      this.hangLoading = false
      if (!resp.success) return
      this.$emit('close')
      this.$parent.init()
    }
  },
  mounted () {
    this.init()
  }
}
</script>

<style scoped lang="scss">

</style>
