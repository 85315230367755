<template>
  <VanPopup v-model="show" style="background: transparent; width:80%; max-width: 375px; min-width: 300px;" class="otc-pop"
    @click-overlay="close">
    <div class="pop-body">
      <div class="pop-title">{{ price }} {{ type === 1 ? $t('otc.buy') : $t('otc.sale') }}</div>
      <van-checkbox-group v-model="batchId">
        <van-checkbox class="check-box"
        checked-color="#6667FF"
        v-for="(item, index) in idList" :key="item"
        :name="item"
        >
        <OrderPriceInfo :index="index" :id="item" :price="price" :type="type" @handleTrade="handleTrade" @handleHideItem="handleHideItem"/>
      </van-checkbox>
      </van-checkbox-group>
      <van-button class="pop-btn"
      v-if="auth"
      :disabled="!batchId.length"
      :loading="batchLoading"
      @click="handleBatchTrade"
      >{{type === 1 ? $t('otc.batchSell') : $t('otc.batchBuy')}}</van-button>
      <van-button class="pop-btn"
     v-else
     :loading="approveLoading"
     @click="approve"
     >{{ $t('otc.approve') }}</van-button>
    </div>
    <div class="close-icon" @click="close">
      <img src="@/assets/close.png" />
    </div>
  </VanPopup>
</template>

<script>
import contractOtc from '../../../contracts/contractOtc'
import contractToken from '../../../contracts/contractToken'
import OrderPriceInfo from './OrderPriceInfo.vue'
import config from '@/config/app.config.js' // 引入配置文件
export default {
  name: '',
  props: ['price', 'type'],
  components: { OrderPriceInfo },
  data () {
    return {
      show: true,
      idList: [],
      batchId: [],
      batchLoading: false,
      tokenAddress: '',
      auth: false,
      approveLoading: true
    }
  },
  methods: {
    close () {
      this.$emit('close')
    },
    async init () {
      this.getIdList()
      await this.gettokenAddress()
      this.getAllowance()
    },
    handleHideItem (index) {
      this.idList.splice(index, 1)
    },
    async getIdList () {
      const resp = await contractOtc.getMarketIds(this.type, this.price)
      if (!resp.success) return
      this.idList = (resp.result || []).map(item => {
        return item
      })
    },
    async gettokenAddress () {
      // type 1买单  卖  2 卖单 买
      const resp = this.type === 2 ? await contractOtc.MUSD() : await contractOtc.MAI()
      if (!resp.success) return
      this.tokenAddress = resp.result
    },
    // 获取授权额度
    async getAllowance () {
      // 获取授权额度
      this.approveLoading = true
      const resp = await contractToken.allowance(
        this.tokenAddress,
        config.otc // 合约地址
      )
      this.approveLoading = false
      if (!resp.success) return
      this.auth = resp.result > 0
    },
    // 授权
    async approve () {
      this.approveLoading = true
      const resp = await contractToken.approve(
        this.tokenAddress,
        config.otc // 合约地址
      )
      this.approveLoading = false
      if (!resp.success) return this.$toast('fail')
      this.$toast('Success')
      this.auth = true
    },
    async handleBatchTrade () {
      this.batchLoading = true
      const resp = await contractOtc.batchTrade(this.batchId)
      this.batchLoading = false
      if (!resp.success) return
      this.$emit('close')
      this.$parent.init()
      this.$parent.$parent.init()
    },
    handleTrade (type, id, info) {
      this.close()
      this.$emit('handleTrade', type, id, info)
    }
  },
  mounted () {
    this.init()
  }
}
</script>
<style scoped lang="scss">
.check-box {
 padding: 15px 0;
 box-sizing: border-box;
 border-bottom: 1px solid #F2F2F2;
 &:last-child {
  border-bottom: none;
 }
}
</style>
